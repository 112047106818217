import * as React from "react"
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layoutEn"
import Warehouses from "../components/warehouses"

import estrella from "../images/estrella-bca.svg"

const MaritimoPage = () => {
	const [expoExpand, setExpoExpand] = React.useState(false)
	const [impoExpand, setImpoExpand] = React.useState(false)

  return (
  <Layout
	seccion="maritimo"	
	title="Ocean Freight"	
	linkTo="experienciaContainer"
	>		
		<div id="experienciaContainer" className="raicoContainer">
			<div className="row">
				<div id="exportacionMar" className="col-12 col-sm-6 pr-0 ">
					<div className={"expTxt bgLightBlue "+(expoExpand ? 'serviceExpand' : '')}>
						
						<div id="" className="expDesc col-sm-11"
									data-aos='fade-up'
									data-aos-duration="600" data-aos-once="true">	
							
							<div className="txtDesc">
								<h2>Ocean Freight <br/>Export Service</h2>
							</div>

							<p>LCL / FCL Ocean Freight Service.</p>
							
							
				
							{!expoExpand && 
							<div className={"expand text-white "+(expoExpand ? 'invisible' : '')}
								onClick={() => setExpoExpand(!expoExpand)}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
								  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
								</svg>
								MORE
							</div>
							}

							<AnimatePresence initial={false}>
								{expoExpand && (
								  <motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
									  open: { opacity: 1, height: "auto" },
									  collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
								  >
									<div className="infoExpanded">
										<p>We can provide you tailored advice for the preparation of export documentation, pick-up throughout the Argentine territory and cargo consolidation in our agent's eligible warehouses.
                                            <br/><br/>
                                            We can ensure the successful arrival of your shipment to the selected port according to your requirements.
                                        </p>
							
										<ul className="beneficios"
										style={{marginBottom: '20px'}}>
											<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
										</ul>
										<ul className="beneficios">
											<li><img src={estrella} alt="" title="" />Consolidation of containers in tax warehouses.</li>
											<li><img src={estrella} alt="" title="" />Advice on the preparation of export documentation. </li>
											<li><img src={estrella} alt="" title="" />Pickup throughout the Argentine territory.</li>
											<li><img src={estrella} alt="" title="" />Destinations: USA, Brazil, Latin America, Europe and Asia.</li>
											<li><img src={estrella} alt="" title="" />Tracking of each export, until the cargo arrives at destination.</li>
											<li><img src={estrella} alt="" title="" />Temporary exports.</li>
											<li><img src={estrella} alt="" title="" />Personalized Customer Service.</li>
											<li><img src={estrella} alt="" title="" />Customs advice.</li>
											<li><img src={estrella} alt="" title="" />Immediate quotations.</li>
											<li><img src={estrella} alt="" title="" />WEB Control Panel for self-management of documentation. </li>
										</ul>
										{/*
										<p className="colorLightBlue">Enviar un mail a maritimo@raiconet.com con <br/>los
										siguientes datos:</p>
										
										<ul className="email">
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Puerto de destino.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>M3 o CBM.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Kg Bruto.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cantidad de bultos.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Medidas de los bultos.</li>
										</ul>
										*/}
									</div>
								  </motion.section>
								)}
							</AnimatePresence>
						</div>
					</div>
				</div>
				<div id="expImg" className="col-sm-6 maritimo expImg" 
								  data-sal="zoom-in"
								  data-sal-delay="0"
								  data-sal-duration="900">
					<div></div>
				</div>
			</div>			
		</div>
		<div id="importacionMar" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6 pr-0 ">
					<div className={"expTxt bgLightBlue "+(impoExpand ? 'serviceExpand' : '')}>
						
						<div className="expDesc col-sm-11"
									data-aos='fade-up'
									data-aos-duration="600" data-aos-once="true">	
							
							<div className="txtDesc"><h2>Ocean Freight <br/>Import Service</h2></div>

							<p>LCL / FCL Ocean Freight Service. </p>
							
							{!impoExpand && 
							<div className={"expand text-white "+(impoExpand ? 'invisible' : '')}
								onClick={() => setImpoExpand(!impoExpand)}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
								  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
								</svg>
								MORE
							</div>
							}

							<AnimatePresence initial={false}>
								{impoExpand && (
								  <motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
									  open: { opacity: 1, height: "auto" },
									  collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
								  >
									<div className="infoExpanded">
										<p>As soon as the international sales order is completed, we work side by side with our customers providing guidance and solid expertise.
										<br/><br/>
										Offering a wide range of maritime LCL / FCL services makes us the best choice. Customers can get access from consolidation and fractioning alongside reconditioning of cargo in our agents' warehouses in key worldwide locations. <br/>
										We are committed to help you find the solution that matches your needs.</p>

										<ul className="beneficios"
										style={{marginBottom: '20px'}}>
											<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
										</ul>
										<ul className="beneficios">
											<li><img src={estrella} alt="" title="" />Agreements with tax warehouses in the forced terms.</li>
											<li><img src={estrella} alt="" title="" />LCL/FCL cargo - Exclusive tariffs with preferential costs.</li>
											<li><img src={estrella} alt="" title="" />Delivery of express documentation.</li>
											<li><img src={estrella} alt="" title="" />Legalization of documentation at origin (Certificate of Origin).</li>
											<li><img src={estrella} alt="" title="" />Route options and weekly departures from each port of origin.</li>
											<li><img src={estrella} alt="" title="" />Personalized Customer Service.</li>
											<li><img src={estrella} alt="" title="" />WEB Control Panel for self-management of documentation.</li>
										</ul>
										
										{/*
										<p className="colorLightBlue">Enviar un mail a maritimo@raiconet.com con <br/>los
										siguientes datos:</p>
										
										<ul className="email">
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Puerto de Origen.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cbm o M3.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Kg Bruto.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cantidad de bultos.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Medidas de los bultos.</li>
										</ul>
										*/}
									</div>
								  </motion.section>
								)}
							</AnimatePresence>
						</div>
					</div>
				</div>
				<div id="expImg" className="col-sm-6 maritimo expImg" 
								  data-sal="zoom-in"
								  data-sal-delay="0"
								  data-sal-duration="900">
					<div></div>
				</div>
			</div>
			
		</div>
		<div id="" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6">
					<div id="correo" className="tarjeta bgRed"
					  data-aos='fade-up'
					  data-aos-duration="600" 
					  data-aos-once="true"
					>
						<div className=" txtTarjeta">
							<h3>ONLY<br/>
							REQUIREMENT:</h3><br/>
							<span>BEING AN IMPORTER'S LICENSE HOLDER</span>
						</div>
					</div>
				</div>
				<div id="cotizaciones" className="col-12 col-sm-6">
					<div className="tarjeta bgBlue"
					  data-aos='fade-up'
					  data-aos-delay="300" 
					  data-aos-duration="600" 
					  data-aos-once="true"
					>
						<div className=" txtTarjeta">
							<span>To request a custom quote</span><br/>
							<p>Please email the following information to marítimo@raiconet.com:</p>
							<ul>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Port of Destination.</li>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Weight (m3).</li>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Number of packages (Pallets).</li>
								<li className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>FOB Value (Usd).</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Warehouses />	
  </Layout>
  )
}

export default MaritimoPage
